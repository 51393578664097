var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading && !_vm.errors && !_vm.success)?_c('form',{staticClass:"w-m-content",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"w-m-body e-input-modern flex"},[_c('div',{staticClass:"flex column w-full",staticStyle:{"min-height":"400px"}},[_c('show-all',{ref:"vars"}),_c('e-row',{staticClass:"m-b-sm font-bold"},[_vm._v(" Título da página: ")]),_c('e-row',{staticClass:"m-b-sm"},[_c('e-col',[_c('erp-input',{attrs:{"autofocus":"","simple-border":"","required":"","placeholder":"Ex.: Sobre nós"},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}})],1)],1),_c('e-row',{staticClass:"m-b-sm font-bold"},[_vm._v(" Nome da página: ")]),_c('e-row',{staticClass:"m-b-sm"},[_c('e-col',[_c('erp-input',{attrs:{"autofocus":"","simple-border":"","required":"","placeholder":"Ex.: Referência em leilões há mais de 10 anos"},model:{value:(_vm.model.pageName),callback:function ($$v) {_vm.$set(_vm.model, "pageName", $$v)},expression:"model.pageName"}})],1)],1),_c('e-row',{staticClass:"m-b-sm m-t-n-xs font-bold"},[_vm._v(" Descrição sobre o página: ")]),_c('e-row',{staticClass:"m-b-sm"},[_c('e-col',[_c('erp-input',{attrs:{"simple-border":"","required":"","placeholder":""},model:{value:(_vm.model.pageDescription),callback:function ($$v) {_vm.$set(_vm.model, "pageDescription", $$v)},expression:"model.pageDescription"}})],1)],1),_c('e-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.source),expression:"!source"}],staticClass:"m-b-sm m-t col-grow flex"},[_c('e-col',{staticClass:"flex col-grow column"},[_c('div',{staticClass:"m-b font-bold w-full flex-zero"},[_vm._v("Conteúdo do template:")]),_c('u-editor',{staticClass:"editor-flex",attrs:{"toolbar":[
      ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
      ['token', 'hr', 'link', 'custom_btn'],
      ['print', 'fullscreen'],
      [
        {
          label: _vm.$uloc.i18n.editor.formatting,
          icon: _vm.$uloc.icon.editor.formatting,
          list: 'no-icons',
          options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
        },
        {
          label: _vm.$uloc.i18n.editor.fontSize,
          icon: _vm.$uloc.icon.editor.fontSize,
          fixedLabel: true,
          fixedIcon: true,
          list: 'no-icons',
          options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
        },
        {
          label: _vm.$uloc.i18n.editor.defaultFont,
          icon: _vm.$uloc.icon.editor.font,
          fixedIcon: true,
          list: 'no-icons',
          options: ['default_font', 'arial', 'arial_black', 'comic_sans', 'courier_new', 'impact', 'lucida_grande', 'times_new_roman', 'verdana']
        },
        'removeFormat'
      ],
      ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
      [
        {
          label: _vm.$uloc.i18n.editor.align,
          icon: _vm.$uloc.icon.editor.align,
          fixedLabel: true,
          list: 'only-icons',
          options: ['left', 'center', 'right', 'justify']
        },
        {
          label: _vm.$uloc.i18n.editor.align,
          icon: _vm.$uloc.icon.editor.align,
          fixedLabel: true,
          options: ['left', 'center', 'right', 'justify']
        }
      ],
      ['undo', 'redo']
    ],"fonts":{
      arial: 'Arial',
      arial_black: 'Arial Black',
      comic_sans: 'Comic Sans MS',
      courier_new: 'Courier New',
      impact: 'Impact',
      lucida_grande: 'Lucida Grande',
      times_new_roman: 'Times New Roman',
      verdana: 'Verdana'
    }},model:{value:(_vm.model.template),callback:function ($$v) {_vm.$set(_vm.model, "template", $$v)},expression:"model.template"}})],1)],1),_c('e-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.source),expression:"source"}]},[_c('e-col',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.template),expression:"model.template"}],staticClass:"w-full wrapper-sm",attrs:{"rows":"10"},domProps:{"value":(_vm.model.template)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.model, "template", $event.target.value)}}})])],1),_c('div',{staticClass:"flex items-center"},[_c('u-toggle',{staticClass:"m-r-sm",model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}}),_vm._v(" Editar código fonte ")],1)],1)]),_c('div',{staticClass:"w-m-footer text-right"},[(_vm.id && !_vm.model.interno)?_c('u-btn',{staticClass:"b-radius-3px pull-left",attrs:{"label":"Excluir","no-caps":"","color":"negative"},on:{"click":_vm.excluir}}):_vm._e(),_c('u-btn',{staticClass:"b-radius-3px",attrs:{"type":"submit","label":_vm.id ? 'Atualizar' : 'Cadastrar',"disable":!_vm.ready,"no-caps":"","color":_vm.ready ? 'primary' : 'blue-grey-2'}})],1)]):(_vm.success)?_c('window-success',[_c('div',{staticClass:"text-center"},[_c('u-icon',{staticClass:"success-circle-140",attrs:{"name":"check-circle","color":"positive","type":"fa","icon-style":"solid"}})],1),_c('h5',{staticClass:"m-t"},[_vm._v("Página registrada com sucesso!")]),_c('div',{staticClass:"text-center m-t"},[_c('timer-close-window',{ref:"timer",on:{"end":function($event){return _vm.$uloc.window.close(_vm.$root.wid)}}})],1)]):_c('window-loading',{attrs:{"errors":_vm.errors,"status":_vm.status}})
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-m-header"},[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"font-14 font-bold no-margin col-grow",staticStyle:{"color":"#293e52"}},[_vm._v("Página do site")]),_c('div',{})])])
}]

export { render, staticRenderFns }