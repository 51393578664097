<template>
  <form @submit.prevent="save" class="w-m-content" v-if="!loading && !errors && !success">
    <div class="w-m-header">
      <div class="flex">
        <p class="font-14 font-bold no-margin col-grow" style="color: #293e52">Página do site</p>
        <div class="">
          <!--<u-btn size="sm" flat label="Variáveis" icon="eye" icon-type="fa" @click="$refs.vars.show()" />-->
        </div>
      </div>
      <!--<div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">Esta seção
        autoriza uma pessoa a acessar o software, com suas respectivas permissões.
      </div>-->
    </div>
    <div class="w-m-body e-input-modern flex">
      <div style="min-height: 400px" class="flex column w-full">
        <show-all ref="vars" />
        <e-row class="m-b-sm font-bold">
          Título da página:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input autofocus simple-border required placeholder="Ex.: Sobre nós"
                       v-model="model.title" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm font-bold">
          Nome da página:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input autofocus simple-border required placeholder="Ex.: Referência em leilões há mais de 10 anos"
                       v-model="model.pageName" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs font-bold">
          Descrição sobre o página:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input simple-border required placeholder="" v-model="model.pageDescription" />
          </e-col>
        </e-row>

        <e-row v-show="!source" class="m-b-sm m-t col-grow flex">
          <e-col class="flex col-grow column">
            <div class="m-b font-bold w-full flex-zero">Conteúdo do template:</div>
            <u-editor class="editor-flex" v-model="model.template" :toolbar="[
        ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
        ['token', 'hr', 'link', 'custom_btn'],
        ['print', 'fullscreen'],
        [
          {
            label: $uloc.i18n.editor.formatting,
            icon: $uloc.icon.editor.formatting,
            list: 'no-icons',
            options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
          },
          {
            label: $uloc.i18n.editor.fontSize,
            icon: $uloc.icon.editor.fontSize,
            fixedLabel: true,
            fixedIcon: true,
            list: 'no-icons',
            options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
          },
          {
            label: $uloc.i18n.editor.defaultFont,
            icon: $uloc.icon.editor.font,
            fixedIcon: true,
            list: 'no-icons',
            options: ['default_font', 'arial', 'arial_black', 'comic_sans', 'courier_new', 'impact', 'lucida_grande', 'times_new_roman', 'verdana']
          },
          'removeFormat'
        ],
        ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
        [
          {
            label: $uloc.i18n.editor.align,
            icon: $uloc.icon.editor.align,
            fixedLabel: true,
            list: 'only-icons',
            options: ['left', 'center', 'right', 'justify']
          },
          {
            label: $uloc.i18n.editor.align,
            icon: $uloc.icon.editor.align,
            fixedLabel: true,
            options: ['left', 'center', 'right', 'justify']
          }
        ],
        ['undo', 'redo']
      ]"
                      :fonts="{
        arial: 'Arial',
        arial_black: 'Arial Black',
        comic_sans: 'Comic Sans MS',
        courier_new: 'Courier New',
        impact: 'Impact',
        lucida_grande: 'Lucida Grande',
        times_new_roman: 'Times New Roman',
        verdana: 'Verdana'
      }" />
          </e-col>
        </e-row>
        <e-row v-show="source">
          <e-col>
            <textarea class="w-full wrapper-sm" rows="10" v-model="model.template" />
          </e-col>
        </e-row>
        <div class="flex items-center">
          <u-toggle v-model="source" class="m-r-sm" />
          Editar código fonte
        </div>


      </div>
    </div>
    <div class="w-m-footer text-right">
      <u-btn @click="excluir" v-if="id && !model.interno" label="Excluir" no-caps color="negative"
             class="b-radius-3px pull-left" />
      <u-btn type="submit" :label="id ? 'Atualizar' : 'Cadastrar'" :disable="!ready" no-caps
             :color="ready ? 'primary' : 'blue-grey-2'"
             class="b-radius-3px" />
    </div>
  </form>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Página registrada com sucesso!</h5>
    <!--<div class="m-t text-blue-grey-7">
      Ela poderá ser utilizada em todo o sistema de templates/documentos.
    </div>-->
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import ERow from '../../../../../layout/components/Row'
import {ErpInput} from 'uloc-vue-plugin-erp'
import ECol from '../../../../../layout/components/Col'
import {UEditor, UToggle} from 'uloc-vue'
import WindowSuccess from '../../../../../layout/window/Success'
import TimerCloseWindow from '../../../../../layout/window/TimerClose'
import WindowLoading from '../../../../../layout/window/Loading'
import {
  newConteudo as create,
  updateConteudo as update,
  find,
  deleteConteudo
} from '../../../../../../domain/conteudo-paginas/services'
import ShowAll from '../../../../../variaveis/components/showAll';

let mock = {
  id: null,
  title: null,
  pageName: null,
  pageDescription: null,
  template: ''
}

export default {
  name: 'PaginasWindowModern',
  components: {
    ShowAll,
    WindowLoading, TimerCloseWindow, WindowSuccess, ECol, ERow, ErpInput, UEditor, UToggle},
  props: ['props', 'id'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      source: false
    }
  },
  mounted () {
    this.id && this.load()
  },
  computed: {
    mock () {
      return mock
    },
    ready () {
      let model = this.model
      return !!(String(model.nome).length > 2 && model.template);

    }
  },
  methods: {
    load () {
      find(this.id)
        .then(response => {
          this.model = response.data
          this.loading = false
        })
        .catch(error => {
          this.alertApiError(error)
          this.loading = false
        })
    },
    excluir () {
      this.$uloc.dialog({
        title: `Tem certeza que deseja excluir esta página?`,
        message: 'Digite EXCLUIR e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'negative'
      }).then(data => {
        if (data.toUpperCase() !== 'EXCLUIR') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Exclusão não efetuada.`
          })
          return
        }
        this.$uloc.notify({
          color: 'negative',
          message: `Excluindo template...`
        })
        deleteConteudo(this.id)
          .then(response => {
            this.$uloc.notify({
              color: 'positive',
              message: `Página deletada com sucesso!`
            })
            this.$uloc.window.emit(this.$root.wid, 'created', this.props)
            this.$uloc.window.close(this.$root.wid)
          })
          .catch(error => {
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    save () {
      console.log('Save')
      this.loading = true

      let data = this.model

      let method = this.id ? (data) => update(this.id, data) : create
      method(data)
        .then(({data}) => {
          this.success = true
          Object.assign(this.model, data)
          this.$nextTick(() => {
            this.$uloc.window.emit(this.$root.wid, 'created', this.props)
            // this.$refs.timer.start()
          })
        })
        .catch(error => {
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    }
  }
}
</script>
